'use strict'
import { viewerAutomationsClientCreator } from '@wix/wix-code-automations-client'
import { createDataSchemasClientForBrowser } from '@wix/wix-data-schemas-client'
import { logger as loggerCreator } from '../logger'
import createApp from './appCreator'
import parseUrl from 'url-parse'

/* global self */
/* eslint-disable no-console */
/* global VERBOSE */

let initAppForPage, createControllers

try {
  const appName = 'dbsm-viewer-app'

  const createWixDataSchemasClient = (
    elementorySupport,
    siteBaseUrl,
    envIsEditor,
  ) => {
    const {
      query: { instance, gridAppId },
    } = parseUrl(`?${elementorySupport.queryParameters}`, true)

    const { protocol, hostname } = parseUrl(siteBaseUrl)

    const baseUrl = envIsEditor
      ? undefined
      : `${protocol}//${hostname}/_api/cloud-data/v1/schemas`

    return createDataSchemasClientForBrowser(instance, gridAppId, {
      baseUrl,
    })
  }

  const appLogger = loggerCreator({
    global: self,
    appName,
  })

  const errorReporter = (message, error) => console.error(message, error) // eslint-disable-line no-console
  const verboseReporter = (...args) => console.verbose(...args)
  const shouldVerbose = !!VERBOSE

  const app = createApp({
    wixDataCreator: ({ baseUrl, envIsEditor, platformizedWixData }) => ({
      wixData: platformizedWixData || self.require('wix-data').default,
      wixDataSchemas: createWixDataSchemasClient(
        global.elementorySupport,
        baseUrl,
        envIsEditor,
      ),
    }),
    automationsClientCreator: elementorySupport =>
      viewerAutomationsClientCreator({
        requestFunction: global.fetch,
        elementorySupport,
      }),
    errorReporter,
    verboseReporter,
    shouldVerbose,
    appLogger,
    getElementorySupport: () => global.elementorySupport,
  })
  initAppForPage = app.initAppForPage
  createControllers = app.createControllers
} catch (e) {
  console.error(e)
}

export { initAppForPage, createControllers }
