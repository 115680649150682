import { mergeWith, isPlainObject, get } from 'lodash-es'
import { mergeReferences } from './utils'

export default class DataStore {
  constructor() {
    this.recordsInfoByDataset = {}
    this.recordsByCollection = {}
  }

  getData({ datasetId, collectionId, includes }) {
    const data = this.recordsInfoByDataset[datasetId]
    return data
      ? {
          totalCount: data.totalCount,
          items: data.itemIds.map(recordId =>
            hideIrrelevantRefs(
              this.recordsByCollection[collectionId][recordId],
              includes,
            ),
          ),
        }
      : null
  }

  getRecord({ collectionId, recordId, includes }) {
    const record = get(this.recordsByCollection, [collectionId, recordId], null)
    if (!record) return getEmptyResponse()
    return hideIrrelevantRefs(
      this.recordsByCollection[collectionId][recordId],
      includes,
    )
  }

  updateCollectionData({ collectionId, data }) {
    const { [collectionId]: records } = this.recordsByCollection
    const { items } = data

    /* copy paste from fes */
    this.recordsByCollection[collectionId] = mergeItemsToRecords(items, records)
  }

  updateStore({ recordsByCollection = {}, recordsInfoByDataset = {} }) {
    this.recordsInfoByDataset = {
      ...this.recordsInfoByDataset,
      ...recordsInfoByDataset,
    }

    for (const [collectionId, records] of Object.entries(recordsByCollection)) {
      this.recordsByCollection[collectionId] = {
        ...this.recordsByCollection[collectionId],
        ...records,
      }
    }
  }

  hasDataset(datasetId) {
    return Boolean(this.recordsInfoByDataset[datasetId])
  }
}

const isValueReference = value => isPlainObject(value) && Boolean(value._id)

const isReferenceExcluded = (includes, fieldName) =>
  !includes || !includes.includes(fieldName)

const hideIrrelevantRefs = (record, includes) => {
  return Object.entries(record).reduce((acc, [fieldName, value]) => {
    if (isValueReference(value) && isReferenceExcluded(includes, fieldName)) {
      acc[fieldName] = value._id
    } else {
      acc[fieldName] = value
    }
    return acc
  }, {})
}

const mergeItemsToRecords = (items, records = {}) =>
  items.reduce((acc, record) => {
    const existingRecord = acc[record._id]
    acc[record._id] = existingRecord
      ? mergeWith(existingRecord, record, mergeReferences)
      : record

    return acc
  }, records)

const getEmptyResponse = () => ({
  totalCount: 0,
  items: [],
})
